@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;700&display=swap');

/* https://stackoverflow.com/questions/20804016/editing-input-type-search-pseudo-element-button-x */
::-webkit-search-cancel-button{
    display: none;
}

body {
    /* avoid jumping left and right on desktop when the content is too short */
    overflow: scroll;
    font-family: 'Work Sans', sans-serif;
}
